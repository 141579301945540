import React, { useState, useEffect } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
//Hooks
import { useFetch } from '../../hooks/useFetch';
//Components
import { Header } from '../common/Header/Header';
import { BarMenu } from '../common/BarMenu/BarMenu';
import { ModalProduct } from '../Menu/ModalProduct';
import LoadingAlert from '../Alerts/LoadingAlert';
import ErrorAlert from '../Alerts/ErrorAlert';
import initData from './initData'

import { Menu } from '../Menu/Menu';
import { StatusMenu } from '../../api/StatusMenu';
import { AuthFetchAPI } from '../../api/Auth';
import { TablesFetchAPI } from '../../api/Tables';
//Styles
import './Home.css';
import BannerBrand from '../Banner/BannerBrand';


export const Home = () => {
   const navigate = useNavigate();
   const [items, setItems] = useState()

   const { business_code, branchoffice_code, machine_code_table } = useParams();

   const [businessCode, setbusinessCode] = useState(business_code);
   const [branchofficeCode, setBranchofficeCode] = useState(branchoffice_code);
   const [machineCodeTable, setMachineCodeTable] = useState(machine_code_table);



   //STATES MENU
   const [categories, setCategories] = useState([])
   const [promotions, setPromotions] = useState([])
   const [groups, setGroups] = useState([])

   const [modalShow, setModalShow] = useState(false);
   const [selectedGroup, setSelectedGroup] = useState({});
   const [segmentsGroup, setSegmentsGroup] = useState([])

   const [shoppingcart, setShoppingcart] = useState([])
   const [shoppingCartCombos, setShoppingCartCombos] = useState([])

   const [hy, setHy] = useState([]);

   //----------------------------auth params localstorage---------------------------------------------------
   let machine = "";
   let business = "";
   let branchoffice = "";
   let logo = ""
   let params = false
   let token = localStorage.getItem("x-access-machine-token")
   if (localStorage.getItem('logo') != null || localStorage.getItem('logo') != undefined || localStorage.getItem('logo') != "") {
      logo = localStorage.getItem('logo')
   }
   if (localStorage.getItem("x-access-machine-token") == "" || localStorage.getItem("x-access-machine-token") == undefined || localStorage.getItem("branchoffice") == "" || localStorage.getItem("branchoffice") == undefined || localStorage.getItem("machine") == "" || localStorage.getItem("machine") == undefined) {

   } else {
      params = true
      branchoffice = JSON.parse(localStorage.getItem('branchoffice'))


      //setBranchoffice(JSON.parse(localStorage.getItem('branchoffice')))
      machine = JSON.parse(localStorage.getItem('machine'));
      business = JSON.parse(localStorage.getItem("business"));
   }
   //---------------------------------------------------------------------------------------------------------

   console.log(
      business.name
   )

   console.log('Empresa ', businessCode);
   console.log('Sucursal ', branchofficeCode);
   console.log('Maquina de la sucursal', machineCodeTable);


   let title_categori = 'Ensalada552'
   let title_categori1 = 'Sopa'

   //----------------------Data general-----------------------\

   //consolidates data into corresponding statements
   const dataconsolidation = (generalData) => {
      if (generalData == 'error') { navigate(`/branch/${business_code}`) }
      let data = generalData


      //section promotions---------------------------------------------------

      //filter promotion by status 1
      let dataPromotions = data.promotions

      let dataTemp = [];
      dataPromotions.forEach((promotionNow) => {
         if (promotionNow.status == 1) {
            dataTemp.push(promotionNow)
         }
      })

      setPromotions(dataTemp)

      //--------------------------------------------------------------------
      //section categories------------------------------------------------
      let dataGroups = data.groups
      let groups_all = dataGroups.filter(({ status }) => status === 1);
      setGroups(groups_all)

      //-------------------Catalogs --------------------------------------
      let dataCatalogs = data.catalogs
      let catalogfilter = dataCatalogs.filter(({ status }) => status === 1);
      let arrayCatalogs = []
      catalogfilter.forEach(catalog => {
         if (catalog.status === 1) {
            catalog.categories.forEach(categorie => {
               arrayCatalogs.push(categorie)
            });
         }
      });

      // Eliminar duplicados de un array de objetos en JavaScript

      // Paso 1: Crear un mapa para rastrear los objetos únicos
      const uniqueMap = new Map();

      // Paso 2: Iterar sobre el jsonArray para llenar el mapa, 
      // asegurándose de que solo se añada la primera instancia de cada id
      arrayCatalogs.forEach(categorie => {
         if (!uniqueMap.has(categorie.category_id)) {
            uniqueMap.set(categorie.category_id, categorie);
         }
      });

      // Paso 3: Convertir el mapa de nuevo en un arreglo
      const uniqueArray = Array.from(uniqueMap.values());
      console.log(uniqueArray);

      //section categories------------------------------------------------
      let dataCategories = data.category
      let typeCodeCategories = 'P'
      let categories_info = dataCategories.filter(({ code }) => code === typeCodeCategories);

      console.log(categories_info);
      let arraycategoriesFilter = []
      categories_info.forEach((categorie) => {
         let status = uniqueArray.find((category_id) => category_id === categorie.category_id)

         console.log(status);
      });


      setCategories(categories_info)


      //---------------------------------------------------------------------



      //--------------------------DATA SHOPPINGCART-------------------------------------------

      if (localStorage.getItem('shoppingCart') == null || localStorage.getItem('shoppingCart') == undefined || localStorage.getItem('shoppingCart') == "") {
         setShoppingcart([])
      } else {
         let datashopingcart = JSON.parse(localStorage.getItem('shoppingCart'))
         setShoppingcart(datashopingcart)
      }

      if (localStorage.getItem('shoppingCartCombos') == null || localStorage.getItem('shoppingCartCombos') == undefined || localStorage.getItem('shoppingCartCombos') == "") {
         setShoppingCartCombos([])
      } else {
         let datashopingcart = JSON.parse(localStorage.getItem('shoppingCartCombos'))
         setShoppingCartCombos(datashopingcart)
      }

   }

   const selectCategorie = (categorie) => {
      localStorage.setItem('selectedCategorie', JSON.stringify(categorie))
      navigate(`/menu/${businessCode}/${branchofficeCode}/${machineCodeTable}`)
   }

   const selectProduct = (data) => {
      setModalShow(true)
      setSelectedGroup(data)
      if (data.segments) {
         setSegmentsGroup(data.segments)
      } else {
         setSegmentsGroup([])
      }
   }

   const selectPromotion = (data) => {
      data.name = data.promotion_name
      data.description = data.promotion_description
      data.group_type = "infinite";
      data.preparation = 0;
      setSelectedGroup(data)
      if (data.segments) {
         setSegmentsGroup(data.segments)
      } else {
         setSegmentsGroup([])
      }
      setModalShow(true)
   }

   const handlerInit = async () => {
      try {
         let response = await initData()
         dataconsolidation(response)
      } catch (err) {
         navigate(`/branch/${businessCode}`)
         console.log(err);
      }
   }

   //================================================================================================
   const getTableById = async () => {
      let token = localStorage.getItem('x-access-machine-token');
      let business = JSON.parse(localStorage.getItem('business'))
      let dataTable = JSON.parse(localStorage.getItem('table'))
      try {

         const response = await TablesFetchAPI.getTableById(dataTable.id, token);
         let tableNow = response.data.table
       
         let codeRoom = localStorage.getItem('codeRoom')
         if (codeRoom != undefined || codeRoom != '' || codeRoom != null) {
            if (codeRoom != tableNow.password) {
               navigate(`/branch/${business_code}/${dataTable.table_code}`)
            }

         } else {
            navigate(`/branch/${business_code}/${dataTable.table_code}`)
         }
         //----------------------------------------------
      } catch (err) {
         navigate(`/branch/${business_code}/${dataTable.table_code}`)
         console.log(err);
      }

   }

   useEffect(() => {
      if (localStorage.getItem('codeRoom') != undefined && localStorage.getItem('codeRoom') != '' && localStorage.getItem('codeRoom') != null) {
         getTableById()
      }
      handlerInit()
   }, [])


   console.log(
      groups
   )

   return (
      <>
         <section className='ecomerce-container-0'>
            <div className='ecomerce-container-1 position-realtive mx-2 pb-2'>
               <Header></Header>


               {/* <------ Promotions ----->   */}
               <div className='ecomerce-container-section-0 tracking-in-expand-forward-top border rounded-4 bg-white pt-0'>


                  <BannerBrand
                     nameBussines={business.name}

                     nameBranch={branchoffice.name}

                  />
                  {/* <------ Categories ----->  */}
                  <span className=' subtitle-ecommerce'>
                     Categorias
                  </span>
                  <div className='mb-4'>
                     <div className="d-flex gap-3 overflow-auto py-1 ps-1">

                        {categories.map((categorie) => (

                           <>

                              {/* <div className='list-pre-ord ers-inputs ' >
                                 <input
                                    className="list-group-item-check "
                                    type="radio"
                                    id={categorie.category_id + "categorie"}
                                    name="checked1"
                                    onChange={() => selectCategorie(categorie)} />
                                 <label className="list-group-item rounded-3 mb-0 fw-bold text-truncate bg-white rounded-2 p-0 categories-menu shadow-sm"
                                    htmlFor={categorie.category_id + "categorie"}
                                 >
                                    <div className="d-flex flex-block   gap-1 py-1 rounded" >
                                       <img src={categorie.img}
                                          alt="Product" className="rounded  flex-shrink-0  img-categories-menu " />
                                    </div>
                                    <div className="d-block px-1">
                                        <span className={`text-categories-menu 
                           text-center ${categorie.category_name.length > 10 ? 'text-categories-menu-slider ' : 'text-categories-menu '}`}>
                                       {categorie.category_name}
                                    </span> 
                                   
                                    </div>
                                 </label>
                              </div> */}


                              <div className="card-categories rounded-3 p-1"
                                 id={categorie.category_id + "categorie"}
                                 onClick={() => selectCategorie(categorie)} >
                                 <div className="d-flex justify-content-start">
                                    <img src={categorie.img}
                                       alt="Product" className="rounded-4 img-categories-menu border border-light-subtle" />
                                 </div>
                                 <span className="card-categories-text text-wrap ps-1" >
                                    {categorie.category_name}
                                 </span>
                              </div>
                           </>

                        ))}
                     </div>
                  </div>

                  {promotions.length > 0 ? <>
                     <span className=' subtitle-ecommerce '>
                        Promociones
                     </span>
                     <div className='mb-4'>
                        {/*<div className="carousel-ecommerce d-block ">
                        <div className="carousel-ecommerce-item ">
                           <img src=''
                              className="d-block img-carrusel-ecommerce border bg-light" alt="..." />
                        </div>
                     </div>*/}

                        <div className="d-flex gap-1 overflow-auto py-1 ps-1">
                           {promotions.map((promotion) => (
                              <>
                                 <div className="carousel-ecommerce-item " onClick={() => selectPromotion(promotion)}>
                                    <img src={promotion.img}
                                       className="d-block img-carrusel-ecommerce border bg-light shadow-sm" alt="..." />
                                 </div>
                              </>
                           ))}
                        </div>
                     </div></> : <></>}


                  <span className='subtitle-ecommerce'>
                     Recomendados
                  </span>
                  <div className="row container-card-favorite py-1 ps-1  mx-2 g-2">

                     {groups.map((group) => (


                        // <div className='col-6 rounded-4 card-favorite-menu mt-2 position-relative' onClick={() => (selectProduct(group))}>
                        //    <div className="d-flex gap-1 py-1 rounded" >
                        //       <img src={group.img}
                        //          alt="Product" className="rounded  flex-shrink-0  img-favorite-menu border" />
                        //    </div>
                        //    <div className="d-block px-1 text-center">
                        //       <span className={`text-favorite-menu `}>
                        //          {group.name}
                        //       </span>
                        //       <span className='text-value-favorite-menu'>
                        //          ${group.price}
                        //       </span>
                        //    </div>
                        // </div>

                        <div className='col-6'>
                           <div className="card-categories rounded-3 p-1" onClick={() => (selectProduct(group))}>
                              <div className="d-flex justify-content-start position-relative">
                                 <img src={group.img}
                                    alt="Product" className="border border-1 rounded-4 img-favorite-menu " />

                                 <span class="badge badge-ranking rounded-2  position-absolute badge-ranking-position"><i class="uil uil-favorite"></i> 4.5</span>

                              </div>
                              <span className="card-recomend-title" >
                                 {group.name}
                              </span>
                              <span className="card-recomend-description" >
                                 {group.description}
                              </span>

                              <span className="card-recomend-price" >
                                 ${group.price}
                              </span>

                           </div>
                        </div>


                     ))}


                  </div>
               </div>

               {/* <div className="border border-danger py-5">

               </div> */}




            </div>

            <BarMenu shoppingcart={shoppingcart} shoppingCartCombos={shoppingCartCombos} />
         </section >
         <ModalProduct
            modalShow={modalShow}
            setModalShow={setModalShow}
            selectedGroup={selectedGroup}
            segmentsGroup={segmentsGroup}
         />
      </>
   )
}
