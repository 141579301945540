import axios from 'axios';


const server = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: { 'Content-Type': 'application/json' }
})

export class TablesFetchAPI {
    //Function to get the branchoffice tables 
    static async getBranchofficeTables(branchoffice_id, token) {
        const res = await server(`/tables/${branchoffice_id}/branchoffice`, {
            method: "get",
            headers: { 'x-access-token': token },
        });
        return res;
    }


    //Function to get the business tables 
    static async getBusinessTables(business_id, token) {
        const res = await server(`/tables/${business_id}/business`, {
            method: "get",
            headers: { 'x-access-token': token },
        });
        return res;
    }

    //Function to get the business tables 
    static async getTableById(table_id, token) {
        const res = await server(`/table/${table_id}`, {
            method: "get",
            headers: { 'x-access-token': token },
        });
        return res;
    }

}