import React, { useState, useEffect, useMemo, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { BarMenu } from '../common/BarMenu/BarMenu'
import { Header } from '../common/Header/Header'
import {Button} from 'react-bootstrap';
import { Tab, Row, Col, Nav } from 'react-bootstrap';
//CSS
import './orders.css';
import InfoOrdersModal from './InfoOrdersModal';
import { PreOrderFetchAPI } from '../../api/PaymentPost/PreOrder';
import { TablesFetchAPI } from '../../api/Tables';
import Success from '../Mesage/Success';
import Danger from '../Mesage/Danger';
import Default from '../Mesage/Default';
import moment from 'moment-timezone';
import Socket from '../../utils/Socket.config'; 
import ErrorAlert from '../Alerts/ErrorAlert';

export const Orders = () => {
   const navigate = useNavigate();
   const [modalShow, setModalShow] = React.useState(false);
   const { business_code, branchoffice_code, machine_code_table } = useParams();
   const [selectedOrder, setSelectedOrder] = useState({ groups: [], combos:[], total: 0, code: "" });
   const [minDate, setMinDate] = useState(moment(new Date()).tz('America/Bogota').format('YYYY-MM-DD') + 'T00:00:00.000+00:00');
   const [maxDate, setMaxDate] = useState(moment(new Date()).tz('America/Bogota').format('YYYY-MM-DD') + 'T23:59:59.000+00:00');
   const [temporalOrdersDeletedData, setTemporalOrdersDeletedData] = useState([]);
   const [temporalOrdersSuccessData, setTemporalOrdersSuccessData] = useState([]);
   const [eventHandler, setEventHandler] = useState(0);
   //state
   const [orders, setOrders] = useState([]);

   let table = localStorage.getItem('table');
   let token = localStorage.getItem('x-access-machine-token');

   if (table !== null && table !== undefined && table !== '') {
      table = JSON.parse(table);
   }


   const getPreOrdertablet = async () => {
      try {
         const response = await PreOrderFetchAPI.getTablePreOrder(table.table_code, business_code, branchoffice_code, token)
         let ordersFilter = response.data.orders.filter((order) => order.table_password && order.table_password == table.password);
         setOrders(ordersFilter);

         console.log(ordersFilter);
      } catch (err) {
         setOrders([]);
         console.log(err);
      }
   }

   // function get order deleted
   const getDeleteTemporal = async () => {
      try {
         const response = await PreOrderFetchAPI.getDeletedTransactionReport(business_code, branchoffice_code, minDate, maxDate, 0, token, table.table_code);
         console.log(response.data.temp_orders);

         let dataOrders = response.data.temp_orders;

         console.log(dataOrders);
         let dataSuccess = []
         let dataDeleted = []
         dataOrders.forEach((item) => {
            if (item.deleted == true) {
               dataDeleted.push(item)
            } else {
               dataSuccess.push(item);
            }
         });

         let ordersFilterDelete = dataDeleted.filter((order) => order.table_password && order.table_password == table.password);
         let ordersFilterSuccess = dataSuccess.filter((order) => order.table_password && order.table_password == table.password);


         setTemporalOrdersDeletedData(ordersFilterDelete);
         setTemporalOrdersSuccessData(ordersFilterSuccess)

      } catch (err) {
         console.log(err);
         setTemporalOrdersDeletedData([]);
      }

   }

   // get Order deleted by id
   const getDeleteOrderTemporalById = async (_id) => {
      try {
         const response = await PreOrderFetchAPI.getDeletedTransactionById(_id, token);
         setModalShow(true)
         setSelectedOrder(response.data.deleted_order)
      } catch (err) {
         console.log(err);
      }
   }

   const emptyOrders = () => {
      return (<><span className='mt-0 px-4 mt-5'>

         <div class="position-relative p-4 text-center text-muted bg-body rounded-4 mx-3">

            <span className='d-block  icon-sad-lg opacity-25'>
               <i class="uil uil-sad-squint"></i>
            </span>
            ¡No hay transacciones!
         </div>
      </span></>)
   }

    //================================================================================================
    const getTableById = async () => {
      let token = localStorage.getItem('x-access-machine-token');
      let business = JSON.parse(localStorage.getItem('business'))
      let dataTable = JSON.parse(localStorage.getItem('table'))
      try {

         const response = await TablesFetchAPI.getTableById(dataTable.id, token);
         let tableNow = response.data.table
       
         let codeRoom = localStorage.getItem('codeRoom')
         if (codeRoom != undefined || codeRoom != '' || codeRoom != null) {
            if (codeRoom != tableNow.password) {
               navigate(`/branch/${business_code}/${dataTable.table_code}`)
            }

         } else {
            navigate(`/branch/${business_code}/${dataTable.table_code}`)
         }
         //----------------------------------------------
      } catch (err) {
         navigate(`/branch/${business_code}/${dataTable.table_code}`)
         console.log(err);
      }

   }


   useEffect(() => {
      if(localStorage.getItem('codeRoom')  != undefined && localStorage.getItem('codeRoom')  != '' && localStorage.getItem('codeRoom')  != null){
         getTableById() 
      }
      getPreOrdertablet();
      getDeleteTemporal();
      setEventHandler(0)
   }, [eventHandler]);


    //Hook to conect the client to the sockets and make the initial charge of data 
   useEffect(() => {
     /* // Connection with the sockets server
      const socket = Socket({ "business_code": business_code })
      // we upate the data when the server send the signal of a new order 
  
      //Update of business temp orders when the temp order is deleted 
      socket.on(`deleteBusinessTempOrder_${business_code}`, (deletedTempOrder) => {
         setTemporalOrdersData((prevTempOrders) => {
            //We get the order position 
            const order_index = prevTempOrders.findIndex(tempOrder => tempOrder._id === deletedTempOrder._id);
            //We delete the prev order 
            prevTempOrders.splice(order_index, 1);
            return prevTempOrders
         })
         //We set the load state 
         setReLoad(true)
         //We close the modal 
         setModalShow(false)
      });

      return () => {
         socket.disconnect();
      }*/
   }, [business_code]);

   return (
      <>


         <section className='ecomerce-container-0'>
            <div className='ecomerce-container-1 position-realtive mx-2 pb-2'>
               <Header></Header>
               {/* <div className="d-flex gap-3">
                  <Success></Success>
                  <Danger></Danger>
                  <Default></Default>
               </div> */}
               <Button onClick={() => (setEventHandler(1), ErrorAlert('','Ordenes actualizadas', 'success'))}><i class="uil uil-refresh"></i> Actualizar</Button>

               <Tab.Container defaultActiveKey="pending" >

                  <Nav variant="underline" className="d-flex gap-3 justify-content-center horizontal-scroll border-bottom  pe-3 bg-white rounded-3 mx-2">
                     <li className="nav-sp-item">
                        <Nav.Link eventKey="pending" title="Preparacion" ><i class="uil uil-process"></i>En preparacion</Nav.Link>
                     </li>
                     <li className="nav-sp-item"  >
                        <Nav.Link eventKey="success" ><i class="uil uil-check-circle"></i>Listos</Nav.Link>
                     </li>
                     <li className="nav-sp-item"  >
                        <Nav.Link eventKey="danger" ><i class="uil uil-sync-slash"></i>Anulados</Nav.Link>
                     </li>
                  </Nav>



                  <Tab.Content>
                     <Tab.Pane eventKey="pending">
                        <div className='ecomerce-container-section-0 tracking-in-expand-forward-top border rounded-4 bg-white pt-0 extra-last-padding'>

                           {orders.length === 0 ? <>{emptyOrders()}</> : <>
                              {orders.map((order, index) => (<>

                                 <article className="card-list-orders">
                                    <span class="badge badge-pending text rounded-2  position-absolute badge-position">pendiente</span>
                                    <span className="card-list-orders-title">
                                       <div>
                                          {/* <img src="https://images.unsplash.com/photo-1508737027454-e6454ef45afd?q=80&w=1972&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" alt=""

               className="object-fit-cover" /> */}
                                          <div className="w-100 p-3">
                                             {/* <div className="d-flex justify-content-between gap-3 mb-1">
               <span class="badge bg-light bg-gradient text-dark">
                  Orden: #26300
               </span>

            
            </div> */}
                                             <div className="d-flex justify-content-center gap-3 mb-1 ">
                                                <h2 className=" text-dark fs-4"> <i class="uil uil-receipt-alt"></i> <b>Orden: </b></h2>


                                                <h2 className=" text-dark fs-4"> <b> {order.code} </b></h2>
                                             </div>

                                             <div className="d-flex justify-content-between gap-3 mb-1">
                                                <h2 className=" text-secondary fs-6"><i class="uil uil-bed"></i> <b>Habitacion: </b></h2>

                                                <h2 className=" text-secondary fs-6"> <b> {order.table_name} </b></h2>
                                             </div>

                                             <div className="d-flex justify-content-between gap-3 mb-1  mt-1 pt-1 btn-sm">
                                                <h2 className=" text-success fs-6"><i class="uil uil-usd-square"></i> <b>Total: </b></h2>

                                                <h2 className=" text-success fs-6"> <b>$  {order.total.toFixed(2)}  </b></h2>
                                             </div>


                                             <div className='d-flex justify-content-center'>
                                                <button type="button" class="btn  text-primary bg-primary-subtle  fw-bold opacity-75 "

                                                   onClick={() => (setModalShow(true), setSelectedOrder(order))}

                                                >Ver más</button>
                                             </div>

                                          </div>
                                       </div>



                                       {/*<details className='rounded-4'>
            <summary>Estado de Orden</summary>
            <div class="order-track">
               <div class="order-track-step">
                  <div class="order-track-status">
                     <span class="order-track-status-dot"></span>
                     <span class="order-track-status-line"></span>
                  </div>
                  <div class="order-track-text ">
                     <p class="order-track-text-stat">Order Received</p>
                     <span class="order-track-text-sub">21st November, 2019</span>
                  </div>
               </div>
               <div class="order-track-step">
                  <div class="order-track-status">
                     <span class="order-track-status-dot"></span>
                     <span class="order-track-status-line"></span>
                  </div>
                  <div class="order-track-text">
                     <p class="order-track-text-stat">Order Processed</p>
                     <span class="order-track-text-sub">21st November, 2019</span>
                  </div>
               </div>
               <div class="order-track-step">
                  <div class="order-track-status">
                     <span class="order-track-status-dot"></span>
                     <span class="order-track-status-line"></span>
                  </div>
                  <div class="order-track-text">
                     <p class="order-track-text-stat">Manufracturing In Progress</p>
                     <span class="order-track-text-sub">21st November, 2019</span>
                  </div>
               </div>
               <div class="order-track-step">
                  <div class="order-track-status">
                     <span class="order-track-status-dot"></span>
                     <span class="order-track-status-line"></span>
                  </div>
                  <div class="order-track-text">
                     <p class="order-track-text-stat">Order Dispatched</p>
                     <span class="order-track-text-sub">21st November, 2019</span>
                  </div>
               </div>
               <div class="order-track-step">
                  <div class="order-track-status">
                     <span class="order-track-status-dot"></span>
                     <span class="order-track-status-line"></span>
                  </div>
                  <div class="order-track-text">
                     <p class="order-track-text-stat">Order Deliverd</p>
                     <span class="order-track-text-sub">21st November, 2019</span>
                  </div>
               </div>
            </div>
         </details>*/}
                                    </span>
                                 </article>  </>))} </>}</div>
                     </Tab.Pane>
                     <Tab.Pane eventKey="success">
                        <div className='ecomerce-container-section-0 tracking-in-expand-forward-top border rounded-4 bg-white pt-0 extra-last-padding'>

                           {temporalOrdersSuccessData.length === 0 ? <>{emptyOrders()}</> : <>
                              {temporalOrdersSuccessData.map((order, index) => (<>

                                 <article className="card-list-orders">
                                    <span class="badge badge-success text rounded-2  position-absolute badge-position">Listo</span>
                                    <span className="card-list-orders-title">
                                       <div>
                                          {/* <img src="https://images.unsplash.com/photo-1508737027454-e6454ef45afd?q=80&w=1972&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" alt=""

className="object-fit-cover" /> */}
                                          <div className="w-100 p-3">
                                             {/* <div className="d-flex justify-content-between gap-3 mb-1">
<span class="badge bg-light bg-gradient text-dark">
Orden: #26300
</span>


</div> */}
                                             <div className="d-flex justify-content-center gap-3 mb-1 ">
                                                <h2 className=" text-dark fs-4"> <i class="uil uil-receipt-alt"></i> <b>Orden: </b></h2>


                                                <h2 className=" text-dark fs-4"> <b> {order.code} </b></h2>
                                             </div>

                                             <div className="d-flex justify-content-between gap-3 mb-1">
                                                <h2 className=" text-secondary fs-6"><i class="uil uil-bed"></i> <b>Habitacion: </b></h2>

                                                <h2 className=" text-secondary fs-6"> <b> {order.table_name} </b></h2>
                                             </div>

                                             <div className="d-flex justify-content-between gap-3 mb-1  mt-1 pt-1 btn-sm">
                                                <h2 className=" text-success fs-6"><i class="uil uil-usd-square"></i> <b>Total: </b></h2>

                                                <h2 className=" text-success fs-6"> <b>$  {order.total.toFixed(2)}  </b></h2>
                                             </div>


                                             <div className='d-flex justify-content-center'>
                                                <button type="button" class="btn  text-primary bg-primary-subtle  fw-bold opacity-75 "

                                                   onClick={() => (getDeleteOrderTemporalById(order._id))}

                                                >Ver más</button>
                                             </div>

                                          </div>
                                       </div>



                                       {/*<details className='rounded-4'>
<summary>Estado de Orden</summary>
<div class="order-track">
<div class="order-track-step">
<div class="order-track-status">
<span class="order-track-status-dot"></span>
<span class="order-track-status-line"></span>
</div>
<div class="order-track-text ">
<p class="order-track-text-stat">Order Received</p>
<span class="order-track-text-sub">21st November, 2019</span>
</div>
</div>
<div class="order-track-step">
<div class="order-track-status">
<span class="order-track-status-dot"></span>
<span class="order-track-status-line"></span>
</div>
<div class="order-track-text">
<p class="order-track-text-stat">Order Processed</p>
<span class="order-track-text-sub">21st November, 2019</span>
</div>
</div>
<div class="order-track-step">
<div class="order-track-status">
<span class="order-track-status-dot"></span>
<span class="order-track-status-line"></span>
</div>
<div class="order-track-text">
<p class="order-track-text-stat">Manufracturing In Progress</p>
<span class="order-track-text-sub">21st November, 2019</span>
</div>
</div>
<div class="order-track-step">
<div class="order-track-status">
<span class="order-track-status-dot"></span>
<span class="order-track-status-line"></span>
</div>
<div class="order-track-text">
<p class="order-track-text-stat">Order Dispatched</p>
<span class="order-track-text-sub">21st November, 2019</span>
</div>
</div>
<div class="order-track-step">
<div class="order-track-status">
<span class="order-track-status-dot"></span>
<span class="order-track-status-line"></span>
</div>
<div class="order-track-text">
<p class="order-track-text-stat">Order Deliverd</p>
<span class="order-track-text-sub">21st November, 2019</span>
</div>
</div>
</div>
</details>*/}
                                    </span>
                                 </article>  </>))}</>} </div>
                     </Tab.Pane>
                     <Tab.Pane eventKey="danger">
                        <div className='ecomerce-container-section-0 tracking-in-expand-forward-top border rounded-4 bg-white pt-0 extra-last-padding'>

                           {temporalOrdersDeletedData.length === 0 ? <>
                              {emptyOrders()}
                           </> : <>
                              {temporalOrdersDeletedData.map((order, index) => (<>

                                 <article className="card-list-orders">
                                    <span class="badge badge-danger text rounded-2  position-absolute badge-position">Anulados</span>
                                    <span className="card-list-orders-title">
                                       <div>
                                          {/* <img src="https://images.unsplash.com/photo-1508737027454-e6454ef45afd?q=80&w=1972&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" alt=""

className="object-fit-cover" /> */}
                                          <div className="w-100 p-3">
                                             {/* <div className="d-flex justify-content-between gap-3 mb-1">
<span class="badge bg-light bg-gradient text-dark">
Orden: #26300
</span>

</div> */}
                                             <div className="d-flex justify-content-center gap-3 mb-1 ">
                                                <h2 className=" text-dark fs-4"> <i class="uil uil-receipt-alt"></i> <b>Orden: </b></h2>


                                                <h2 className=" text-dark fs-4"> <b> {order.code} </b></h2>
                                             </div>

                                             <div className="d-flex justify-content-between gap-3 mb-1">
                                                <h2 className=" text-secondary fs-6"><i class="uil uil-bed"></i> <b>Habitacion: </b></h2>

                                                <h2 className=" text-secondary fs-6"> <b> {order.table_name} </b></h2>
                                             </div>

                                             <div className="d-flex justify-content-between gap-3 mb-1  mt-1 pt-1 btn-sm">
                                                <h2 className=" text-success fs-6"><i class="uil uil-usd-square"></i> <b>Total: </b></h2>

                                                <h2 className=" text-success fs-6"> <b>$  {order.total.toFixed(2)}  </b></h2>
                                             </div>


                                             <div className='d-flex justify-content-center'>
                                                <button type="button" class="btn  text-primary bg-primary-subtle  fw-bold opacity-75 "

                                                   onClick={() => (getDeleteOrderTemporalById(order._id))}

                                                >Ver más</button>
                                             </div>

                                          </div>
                                       </div>



                                       {/*<details className='rounded-4'>
<summary>Estado de Orden</summary>
<div class="order-track">
<div class="order-track-step">
<div class="order-track-status">
<span class="order-track-status-dot"></span>
<span class="order-track-status-line"></span>
</div>
<div class="order-track-text ">
<p class="order-track-text-stat">Order Received</p>
<span class="order-track-text-sub">21st November, 2019</span>
</div>
</div>
<div class="order-track-step">
<div class="order-track-status">
<span class="order-track-status-dot"></span>
<span class="order-track-status-line"></span>
</div>
<div class="order-track-text">
<p class="order-track-text-stat">Order Processed</p>
<span class="order-track-text-sub">21st November, 2019</span>
</div>
</div>
<div class="order-track-step">
<div class="order-track-status">
<span class="order-track-status-dot"></span>
<span class="order-track-status-line"></span>
</div>
<div class="order-track-text">
<p class="order-track-text-stat">Manufracturing In Progress</p>
<span class="order-track-text-sub">21st November, 2019</span>
</div>
</div>
<div class="order-track-step">
<div class="order-track-status">
<span class="order-track-status-dot"></span>
<span class="order-track-status-line"></span>
</div>
<div class="order-track-text">
<p class="order-track-text-stat">Order Dispatched</p>
<span class="order-track-text-sub">21st November, 2019</span>
</div>
</div>
<div class="order-track-step">
<div class="order-track-status">
<span class="order-track-status-dot"></span>
<span class="order-track-status-line"></span>
</div>
<div class="order-track-text">
<p class="order-track-text-stat">Order Deliverd</p>
<span class="order-track-text-sub">21st November, 2019</span>
</div>
</div>
</div>
</details>*/}
                                    </span>
                                 </article>  </>))} </>}</div>
                     </Tab.Pane>
                  </Tab.Content>


               </Tab.Container>


            </div>

            <BarMenu></BarMenu>
         </section>

         <InfoOrdersModal modalShow={modalShow}
            setModalShow={setModalShow} selectedOrder={selectedOrder} />
      </>
   )
}
