import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'

function ModalIngredient({ setShowModalIngredient, showModalIngredient, groupNow, logo, background, formatJsonToAddShoppingCart, segmentsNow }) {

  const handleButtonFocus = (buttonId) => {
    setButtonFocusId(buttonId);
  };

  const handleButtonBlur = () => {
    setButtonFocusId(null);
  };

  //Focus
  const [isButtonFocusedId, setButtonFocusId] = useState(null);

  return (
    <>



      <Modal
        centered
        className='modal-dialog-kk'
        size="xl"
        show={showModalIngredient}
        onHide={() => setShowModalIngredient(false)}>

        <Modal.Header>
          <div className='d-flex justify-content-between'>

            <div className="d-flex flex-block   gap-1 py-1 rounded" >
              <img src={groupNow.img === null || groupNow.img === '' ? logo : groupNow.img}
                alt="Product" className="rounded  flex-shrink-0  img-categories-menu" />
            </div>



            {/* Producto Description*/}
            <div className='kiosk-segments-description py-2'>
              <h5 className='kiosk-subtitle-md  scale-up-horizontal-left text-wrap m-3'>{groupNow.name}</h5>
              <p className='kiosk-description-product mx-3 text-dark'>{groupNow.description}</p>
            </div>
          </div>

        </Modal.Header>
        <Modal.Body>
          <div>
            {segmentsNow.length > 0 ? <>
              <h5 className='kiosk-subtitle-md  scale-up-horizontal-left text-wrap m-3 text-center'>Ingredientes</h5>

              <div className='kiosk-container_section kiosk-segments-items container-fluid  bg-white ' style={{ backgroundImage: `url(${background})` }} >
                <div className='mt-3 container-card-content-wrapper'>
                  {segmentsNow.map((segment) => (<>


                    <article className='  my-1  '>
                      <div className='d-block mt-2'>
                        <span className='kiosk-subtitle-md text-dark'>{segment.name}</span>
                      </div>
                      <div className='d-flex gap-3 overflow-auto ps-1 position-relative'>
                        {segment.items.map((item, index) => (
                          <div className='list-info-products-inputs-kiosk'>
                            <input
                              className="list-group-products-check "
                              type={segment.type == "UNIQ" ? "radio" : "checkbox"}
                              name={"listGroupRadioGrid" + segment.segment_id}
                              id={"listGroupRadioGrid" + segment.segment_id + item.item_id}
                              value=""
                              defaultChecked={segment.default_items.length == 0 ? index == 0 ? true : false : segment.default_items.find((default_item) => default_item.item_id == item.item_id) != undefined ? true: false}   
                            />

                            <>{/*defaultChecked={segment.segment_item_id_default == null ? index == 0 ? true : false : segment.segment_item_id_default == item.item_id ? true : false}*/}</>

                            <label className="list-group-item rounded-3 mb-0 fw-bold text-truncate bg-body  rounded-2 p-1 "
                              htmlFor={"listGroupRadioGrid" + segment.segment_id + item.item_id}
                            >
                              <div className="d-flex flex-block   gap-1 py-1 rounded" >
                                <img src={item.img === null || item.img === '' ? logo : item.img}
                                  alt="Product" className="rounded  flex-shrink-0  img-categories-menu" />
                              </div>

                              <span className={`text-categories-menu 
                           text-center ${item.name.length > 10
                                  ? 'text-categories-menu-slider '
                                  : 'text-categories-menu '}`}>
                                {item.name}
                              </span>
                              <h5 className='text-center text-price-menu '>${parseFloat(item.price).toFixed(2)}</h5>
                            </label>
                          </div>
                        ))}
                      </div>
                    </article>
                  </>))}
                </div>
              </div>
            </> : <><div className=' m-5'></div></>}
          </div>



        </Modal.Body>

        <div className='position-absolute w-100 d-flex justify-content-between bottom-0 start-0'>
          <button
            onClick={() => setShowModalIngredient(false)}

            className={`btn btn-danger kiosk-subtitle-md  
          kiosk-border-4 py-3 m-3 w-100 position-relative  rounded-4
          ${isButtonFocusedId === "cancel" ? 'focused' : ''}`}
            onFocus={() => handleButtonFocus("cancel")}
            onBlur={handleButtonBlur}
          >Cancelar
            {/* Focus  */}
            <div className="position-absolute postition-icon-btn-accions">
              {isButtonFocusedId === "cancel" &&
                <img src='' alt="Hand" className='icon-focus' />}
            </div>
          </button>

          <button
            className={`btn btn-success kiosk-subtitle-md  
          kiosk-border-4 py-3 m-3 w-100 position-relative rounded-4`}
            onClick={() => ( formatJsonToAddShoppingCart(groupNow, true), setShowModalIngredient(false))}
          >Guardar </button>

        </div>

      </Modal>
    </>
  )
}

export default ModalIngredient