import React, { useState, useEffect } from 'react'
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import ModalIngredient from './ModalIngredient';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';

import { ProductsFetchAPI } from '../../api/PaymentPost/Products';


export const ModalProduct = ({ modalShow, setModalShow, selectedGroup, segmentsGroup, shoppingcart, shoppingCartCombos, setShoppingCartCombos, logo, branchoffice, token }) => {
   let title_categori = 'Ensalada552'
   let title_categori1 = 'Sopa'
   const [segments, setSegments] = useState([])
   const [amount, setAmount] = useState(1)
   const [handlerevent, setHandlerEvent] = useState(0)
   const [modalViewImg, setModalViewImg] = useState(false)

   //combos
   const [isOpen, setIsOpen] = useState(false);
   const [activeTab, setActiveTab] = useState(0);
   const [comboSelect, setComboSelect] = useState([])
   const [statusRequest, setStatusRequest] = useState(true)
   const [groupNow, setGroupNow] = useState({ nama: '', price: 0.00, img: '', description: '', segments: [] })
   const [segmentsNow, setSegmentsNow] = useState([])
   const [showModalIngredient, setShowModalIngredient] = useState(false)
   const [handlerEventAction, sethandlerEventAction] = useState(0)
   const [stepsReadyComboNow, setStepsReadyComboNow] = useState([])


   const handleClose = () => setModalViewImg(false);


   const navigate = useNavigate()
   const { business_code, branchoffice_code, machine_code_table } = useParams();


   //Tabs combo
   const Tabs = () => {
      let tabs = selectedGroup.setGroups
      const changeTab = (tabIndex) => {
         setActiveTab(tabIndex);
      };

      const toggleOffCanvas = () => {
         setIsOpen(!isOpen);
      };

      return (
         <div className=' border-top '>
            <div className="list-tabs-kiosk">
               <div className="container-list-kiosk_section d-flex py-4  my-3 gap-5 border align-items-center">
                  {tabs.map((tab, index) => (
                     <>
                        <label
                           // className="card  border-0 rounded-5 mx-3"  
                           className={
                              tab.ready == true
                                 ? 'card-tab  border-0 rounded-5  '
                                 : index === activeTab
                                    ? 'card-active-tab   '
                                    : 'opacity-50  '}
                           key={index} onClick={() => changeTab(index)}  >
                           <span class="plan-details-tab">
                              <span class="plan-type">   {tab.name}  {tab.ready == true ? <><i class="uil uil-check-circle text"></i></> : <></>} </span>

                           </span>
                        </label>

                     </>

                  ))}
               </div>

               <div className="tab-content">
                  <div className=' container '  >
                     <div className='mt-3 container-card-content-wrapper'>
                        <article className='  my-1  '>
                           <h5 className='kiosk-subtitle-segnments scale-up-horizontal-left text-wrap mt-2'>Selecciona {tabs[activeTab].name} </h5>
                           <div className='d-flex gap-3 overflow-auto  position-relative overflow-x border rounded-5 py-5 ps-2 pe-5'>
                              {tabs[activeTab].groups.map((group, index) => (
                                 <>
                                    <div className='list-info-products-inputs-kiosk'
                                       onClick={() => actionCheckIngradent(group, "setGroup" + tabs[activeTab].set_id + group.group_id, true)}>
                                       <input
                                          className="list-group-products-check "
                                          type={tabs[activeTab].count > 1 ? "checkbox" : "radio"}

                                          name={"setGroup" + tabs[activeTab].set_id}
                                          id={"setGroup" + tabs[activeTab].set_id + group.group_id}
                                          value=""
                                          checked={false}
                                       />

                                       <label className="list-group-item rounded-3 mb-0 fw-bold text-truncate bg-body  rounded-2 p-1 "
                                          htmlFor={"setGroup" + tabs[activeTab].set_id + group.group_id}
                                       >
                                          <div className="d-flex flex-block   gap-1 py-1 rounded" >
                                             <img src={group.img === null || group.img === '' ? logo : group.img}
                                                alt="Product" className="rounded  flex-shrink-0  img-categories-menu" />
                                          </div>

                                          <span className={`text-categories-menu 
                             text-center ${group.name.length > 10
                                                ? 'text-categories-menu-slider '
                                                : 'text-categories-menu '}`}>
                                             {group.name}
                                          </span>
                                          <h5 className='text-center text-price-menu '>${parseFloat(group.price).toFixed(2)}</h5>
                                       </label>
                                    </div>

                                 </>
                              ))}
                           </div>
                        </article>
                     </div>
                  </div>
               </div>
            </div>
            <div className='ingredients-section position-relative' >

               {comboSelect.length > 0
                  ? <>
                     <div className='open-btn  text-center'>
                        <button className="btn-off-canvas position-relative " onClick={() => toggleOffCanvas()}>

                           <span className='d-block'>
                              <i class="uil uil-angle-up"></i>
                           </span>
                           Detalles de Combo
                        </button>
                     </div>
                  </>
                  : <></>}
               <div className={`off-canvas ${isOpen ? 'open' : ''}`}>
                  <h2 class="text-center"><span class="kiosk-container_section_title  rounded-4 "> Detalles</span></h2>
                  <button type="button" class="position-absolute top-0 end-0 p-3 m-3 btn-close bg-secondary bg-opacity-10 rounded-pill fs-2" aria-label="Close " onClick={() => toggleOffCanvas()}></button>
                  <ul>
                     <li>
                        <div className=' container-fluid scrool-ingredientes' style={{ overflowY: 'auto' }}>

                           {comboSelect.map((tab, index) => (
                              <article className='m-1 '>
                                 <div className='d-block mt-2 '>
                                    <div className='pb-2 ' >
                                       {
                                          !tab.groupsselected.length
                                             ?
                                             <>
                                                <div className='mt-5 '>
                                                   <div className='d-block text-center fw-bold text-msg-empty-orders'>
                                                      <span className='fw-bold '>
                                                         ¡Tu carrito está vacío!
                                                      </span>  <br /> No has agregado ningún producto aún.
                                                   </div>
                                                </div>
                                             </>
                                             : tab.groupsselected.map((product, index) => (
                                                <div className=' overflow-scroll '>
                                                   <div className='bd-highlight  mt-1 title-product-text fw-bold text-wrap m-1'>{tab.name}
                                                   </div>
                                                   <div class="w-100  rounded-4 bg-white p-4">
                                                      <div class="d-flex align-items-center ">
                                                         <div class=" w-100">
                                                            <div className="flex-shrink-0">
                                                               <div className='d-flex align-items-center gap-3'>
                                                                  <img className='m-1 mt-2 bg-light  border rounded-3 img-order-summary  ' src={product.img} alt="..." />

                                                                  <div className="bd-highlight  mt-1 title-product-text fw-bold text-wrap">
                                                                     {product.name}
                                                                  </div>

                                                                  <div className="d-flex justify-content-start  ">
                                                                     <div className=" title-product-text fw-normal text-muted "
                                                                        translate='no'>{product.amount} X ${product.price.toFixed(2)}
                                                                     </div>
                                                                  </div>
                                                               </div>
                                                            </div>
                                                         </div>
                                                         <div class="p-2 flex-shrink-1">
                                                            <div className='d-flex justify-content-center gap-3'>
                                                               <button className='  btn border rounded-4' onClick={() => (setActiveTab(tab.activeTab), getdataGroup(product, false, true))}>
                                                                  <i class="uil uil-pen icon-delete-kk px-2"></i>
                                                                  Editar
                                                               </button>
                                                               <button className='  btn border rounded-4' onClick={() => deleteSetGroupOfComboSelected(tab.activeTab)}><i class="uil uil-trash-alt icon-delete-kk px-2  "></i>
                                                                  Borrar
                                                               </button>
                                                            </div>
                                                         </div>
                                                      </div>
                                                      <div className={`d-flex justify-content-start w-100`}>
                                                         <details close >
                                                            <summary className=''>
                                                               <span className='badge bg-light text-dark summary-text mt-1 fw-bold border rounded-4 my-1 text-wrap '>
                                                                  <i className="uil uil-info-circle  px-1 "></i>  Detalles
                                                               </span>
                                                            </summary>
                                                            <div className='border rounded-4 p-4   w-100'>
                                                               {product.segments.length > 0 ? <> {product.segments.map(item => (
                                                                  <div className='w-100 mt-1 flex-column  w-100'>
                                                                     <span className='m-0 p-0 summary-text fw-bold'>{item.segment_name}:</span>
                                                                     <span className='ms-2 summary-text '>{item.name} /</span>
                                                                     <span className='ms-2 summary-text '>{parseFloat(item.price).toFixed(2)} </span>
                                                                  </div>
                                                               ))}</> : <></>}
                                                            </div>
                                                         </details>
                                                      </div>
                                                   </div>
                                                </div>
                                             ))}
                                    </div>
                                 </div>
                              </article>
                           ))}
                        </div>
                     </li>
                  </ul>
               </div>

            </div>
         </div>
      );
   };

   //function valid action to group checked
   const actionCheckIngradent = (group, datacheck, addAuto) => {
      setStatusRequest(true)
      if (datacheck != undefined) {
         if (document.getElementById(datacheck).checked) {
            getdataGroup(group, addAuto)
         } else {
            if (addAuto == true) {
               getdataGroup(group, addAuto)
            } else {
               console.log("no checked")
            }
         }
      }
   }

   //get data group by setgroups
   const getdataGroup = async (group, addAuto, modification) => {
      console.log(group);
      setStatusRequest(false)
      try {
         if (statusRequest == true) {
            const response = await ProductsFetchAPI.getSegmentsByProducts(group.group_id, 1, branchoffice.id, token)
            let dataGroup = response.data.group

            if (modification == true) {
               dataGroup.price = group.base_price
            } else {
               dataGroup.price = group.price
            }

            setSegmentsNow(response.data.data);
            setGroupNow(dataGroup);


            if (addAuto == true) {
               console.log("Entre aqui");
               formatJsonToAddShoppingCart(dataGroup, true, addAuto, response.data.data)
            } else {
               console.log(dataGroup);
               setShowModalIngredient(true);
            }

            setStatusRequest(true)
         }
      } catch (err) {

         setSegmentsNow([]);
         setGroupNow(group);
         if (addAuto == true) { formatJsonToAddShoppingCart(group, true, true, []) }
         setStatusRequest(true)
         console.log(err.response.data.error);
      }

   }

   //delete group of setGroups
   const deleteSetGroupOfComboSelected = (setgroupActive) => {

      selectedGroup.setGroups[setgroupActive].ready = false
      let removeFilter = comboSelect.filter(({ activeTab }) => activeTab != setgroupActive)
      setComboSelect(removeFilter)
      stepSaveCombos()
      sethandlerEventAction(1)
   }

   //Esta funcion verifica que todos los pasos del combo se cumplan 
   const stepSaveCombos = () => {
      //guarda tamaño del setgroups original
      let sizeSetGroups = selectedGroup.setGroups.length;
      //convierte el tamaño del arreglo en un nuevo indice
      let array = Array.from({ length: sizeSetGroups }, (_, index) => index);
      let stepReady = [];

      //recorre el arreglo de los setGroups ya seleccionados y gusrda su index en un array 
      comboSelect.forEach((group) => {
         stepReady.push(group.activeTab)
      })

      setStepsReadyComboNow(stepReady)

      //Filtra los indices faltantes 
      let pendingStep = array.filter(index => !stepReady.includes(index));

      if (pendingStep.length > 0) {
         setActiveTab(pendingStep[0])
         setIsOpen(true);
         //ErrorAlert("Faltan pasos por completar", "Por favor completa todos los pasos del combo", "warning");
      } else {

         console.log("Todos los pasos del combo se han cumplido: ")

         let comboReadyFormat = {}
         comboReadyFormat.name = selectedGroup.name
         comboReadyFormat.description = selectedGroup.description
         comboReadyFormat.price = selectedGroup.price
         comboReadyFormat.priceBase = selectedGroup.price
         comboReadyFormat.img = selectedGroup.img
         comboReadyFormat.combo_id = selectedGroup.combo_id
         comboReadyFormat.descuento = 0
         comboReadyFormat.discount = 0
         comboReadyFormat.unit_discount = 0
         comboReadyFormat.taxes = selectedGroup.taxes
         comboReadyFormat.external_reference = selectedGroup.external_reference
         comboReadyFormat.note = ""
         comboReadyFormat.delivery_type = "comer aqui"
         comboReadyFormat.amount = amount
         comboReadyFormat.setGroups = []
         comboReadyFormat.setGroupString = ""

         let newPrice = 0
         comboSelect.forEach((combo) => {
            let newSetGroup = {}
            newSetGroup.name = combo.name
            newSetGroup.set_id = combo.set_id
            newSetGroup.groups = combo.groupsselected
            newSetGroup.isMainSetGroup = combo.isMainSetGroup
            comboReadyFormat.setGroups.push(newSetGroup)
            comboReadyFormat.setGroupString = comboReadyFormat.setGroupString + combo.name

            combo.groupsselected.forEach((group) => {
               comboReadyFormat.setGroupString = comboReadyFormat.setGroupString + group.segments_string
               newPrice = newPrice + group.price
            })
         })

         //comboReadyFormat.price = (selectProduct.group.price + newPrice)
         comboReadyFormat.price = (newPrice)
         console.log("Precio del grupo");
         console.log(newPrice);
         let coincidence = false

         let cant = 0
         let datacombo = {}
         for (let i = 0; i < shoppingCartCombos.length; i++) {
            if (shoppingCartCombos[i].setGroupString === comboReadyFormat.setGroupString) {
               if (shoppingCartCombos[i].discount == 0) {
                  coincidence = true
                  cant = (shoppingCartCombos[i].amount + amount)
                  console.log(cant);
                  shoppingCartCombos[i].amount = cant
                  datacombo = shoppingCartCombos[i]
               }
            }
         }
         console.log(cant);
         console.log(datacombo.amount);


         if (coincidence == true) {


            localStorage.setItem('shoppingCartCombos', JSON.stringify(shoppingCartCombos))

         } else {
            console.log(comboReadyFormat);
            let newShoppingCart = shoppingCartCombos
            newShoppingCart.push(comboReadyFormat)
            localStorage.setItem('shoppingCartCombos', JSON.stringify(newShoppingCart))
            setShoppingCartCombos([...shoppingCartCombos, comboReadyFormat])

         }

         setComboSelect([])
         setModalShow(false)
         navigate(`/cart/${business_code}/${branchoffice_code}/${machine_code_table}`)
      }
   }

   //===========================================================================================================


   // format json of product to add shopppingcart
   const formatJsonToAddShoppingCart = (dataProduct, status, addAuto, segmentsAuto) => {

      let formatProductAdd = {}
      let idCheckBox = ""
      let detailSegments = []
      if (dataProduct == undefined) {
         formatProductAdd = selectedGroup
         idCheckBox = "listGroupRadioGrid"
         detailSegments = segmentsGroup
      } else {
         formatProductAdd = dataProduct
         idCheckBox = "listGroupRadioGrid"

         if (addAuto == true) {
            detailSegments = segmentsAuto

            console.log(detailSegments);
         } else {
            detailSegments = segmentsNow
            console.log(formatProductAdd);
         }

      }

      if (formatProductAdd.img == null || formatProductAdd.img == "" || formatProductAdd.img == undefined) {
         formatProductAdd.img = logo
      }
      //For default we set that the amount of the selected product depending the number 

      formatProductAdd.segments_originally = detailSegments
      formatProductAdd.amount = amount;
      formatProductAdd.cantidadItem = amount;
      formatProductAdd.delivery_type = "comer aqui";
      formatProductAdd.note = '';
      formatProductAdd.unit_discount = 0;
      formatProductAdd.product_discount = 0;
      formatProductAdd.base_price = formatProductAdd.price;
      formatProductAdd.valueOriginal = formatProductAdd.price;
      formatProductAdd.descuento = formatProductAdd.product_discount;
      formatProductAdd.discount = formatProductAdd.product_discount;
      //For default we initialice the segments empty of the selected product 
      formatProductAdd.segments = [];
      formatProductAdd.DetalleSegmentos = [];
      formatProductAdd.segments_string = ""

      //find segments checked
      let temporal_segment_string = ""
      detailSegments.forEach((segment) => {

         if (addAuto == true) {
            if (segment.segment_item_id_default == null) {
               let dataFirsItem = segment.items[0]
               addItem(dataFirsItem)
            } else {
               segmentsItems()
            }
         } else {
            segmentsItems()
         }

         //Scooch items of segment
         function segmentsItems() {
            segment.items.forEach((item) => {

               if (addAuto == true) {//if addAuto is true add automatic item defauld in group
                  if (item.item_id == segment.segment_item_id_default) {
                     addItem(item)

                     console.log("Encontre item por defecto");
                  }

               } else {
                  let idCheckedItem = document.getElementById(idCheckBox + segment.segment_id + item.item_id)
                  if (idCheckedItem.checked == true) {
                     addItem(item)
                  }
               }
            })
         }

         //Add item selected
         function addItem(itemNow) {
            itemNow.amount = amount
            temporal_segment_string = formatProductAdd.segments_string + " " + segment.name + ": " + itemNow.name
            formatProductAdd.segments_string = formatProductAdd.segments_string + " " + segment.name + ": " + itemNow.name
            formatProductAdd.segments.push({ ...itemNow, segment_name: segment.name, cantidadItem: amount, item: itemNow })
            formatProductAdd.DetalleSegmentos.push({ ...itemNow, segment_name: segment.name, cantidadItem: amount, item: itemNow })
            formatProductAdd.price = formatProductAdd.price + itemNow.price
         }
      });

      if (status == true) {
         saveDataGroupByCombo(formatProductAdd)
      } else {
         if (formatProductAdd.promotion_id) {
            //addpromotionToShoppiingCart(formatProductAdd)
         } else {
            addProductSelectToShoppingcart(formatProductAdd, temporal_segment_string)
         }

         //clearDataInfoProduct()
      }
   }

   //add combo to shoppingcart
   const saveDataGroupByCombo = (data) => {
      let setGroup = selectedGroup.setGroups[activeTab]
      setGroup.activeTab = activeTab
      setGroup.ready = true

      console.log(selectedGroup.setGroups[activeTab])
      console.log(data)

      let comboFind = comboSelect.find(({ set_id }) => set_id === setGroup.set_id)
      if (comboFind) {
         let comboIndex = comboSelect.findIndex(({ set_id }) => set_id === setGroup.set_id)
         console.log("DATA POR RESOLVER");
         let groupindex = comboSelect[comboIndex].groupsselected.findIndex(({ group_id }) => group_id === data.group_id)

         console.log(comboSelect[comboIndex].groupsselected);
         console.log(comboSelect[comboIndex].count);

         if (groupindex == -1) {

            if (comboSelect[comboIndex].count > 1) {
               comboSelect[comboIndex].groupsselected.push(data)

            } else {
               comboSelect[comboIndex].groupsselected = [data]
            }

         } else {
            comboSelect[comboIndex].groupsselected[groupindex] = data
         }

      } else {
         setGroup.groupsselected = []
         setGroup.groupsselected.push(data)
         comboSelect.push(setGroup)
      }
      stepSaveCombos()
   }

   // format json of product to add shopppingcart
   {/*const formatJsonToAddShoppingCart = () => {

      let formatProductAdd = selectedGroup

      if (formatProductAdd.img == null || formatProductAdd.img == "" || formatProductAdd.img == undefined) {
         formatProductAdd.img = localStorage.getItem('logo')
      }
      //For default we set that the amount of the selected product depending the number 
      formatProductAdd.amount = amount;
      formatProductAdd.cantidadItem = amount;
      formatProductAdd.delivery_type = "comer aqui";
      formatProductAdd.note = '';
      formatProductAdd.unit_discount = 0;
      formatProductAdd.product_discount = 0;
      formatProductAdd.base_price = formatProductAdd.price;
      formatProductAdd.descuento = formatProductAdd.product_discount;
      formatProductAdd.discount = formatProductAdd.product_discount;
      //For default we initialice the segments empty of the selected product 
      formatProductAdd.segments = [];
      formatProductAdd.DetalleSegmentos = [];
      formatProductAdd.segments_string = ""

      //find segments checked
      let temporal_segment_string = ""
      segmentsGroup.forEach((segment) => {
         segment.items.forEach((item) => {
            let idCheckedItem = document.getElementById("listGroupRadioGrid" + segment.segment_id + item.item_id)

            if (idCheckedItem.checked == true) {
               item.amount = amount
               temporal_segment_string = formatProductAdd.segments_string + " " + segment.name + ": " + item.name
               formatProductAdd.segments_string = formatProductAdd.segments_string + " " + segment.name + ": " + item.name
               formatProductAdd.segments.push({ ...item, segment_name: segment.name, cantidadItem: amount, item: item })
               formatProductAdd.DetalleSegmentos.push({ ...item, segment_name: segment.name, cantidadItem: amount, item: item })
               formatProductAdd.price = parseFloat(formatProductAdd.price + item.price).toFixed(2)
            }
         })

      });

      addProductShopingcart(formatProductAdd)

   }*/}


   {/*const addProductShopingcart = (product) => {
      let shoppingCart = localStorage.getItem('shoppingCart');
      let dataProduct = []
      if (shoppingCart == null || shoppingCart == undefined || shoppingCart == "") {
         dataProduct = []
      } else {
         dataProduct = JSON.parse(shoppingCart)
      }

      dataProduct.push(product)
      localStorage.setItem('shoppingCart', JSON.stringify(dataProduct))

      setModalShow(false)
      navigate(`/cart/${business_code}/${branchoffice_code}/${machine_code_table}`)
   }*/}

   //add product to shopingcart 
   const addProductSelectToShoppingcart = (product, temporal_segment_string) => {
      let temporalShopingCart = []

      let shoppingCart = localStorage.getItem('shoppingCart');
      if (shoppingCart == null || shoppingCart == undefined || shoppingCart == "") {
         temporalShopingCart = []
      } else {
         temporalShopingCart = JSON.parse(shoppingCart)
      }

      let turn = 0
      //check item string segments into shopingcart
      for (let shopping = 0; shopping < temporalShopingCart.length; shopping++) {
         if (product.group_id == temporalShopingCart[shopping].group_id) {
            if (temporalShopingCart[shopping].segments_string === temporal_segment_string) {
               if (temporalShopingCart[shopping].descuento == 0) {
                  temporalShopingCart[shopping].amount = (temporalShopingCart[shopping].amount + amount)
                  temporalShopingCart[shopping].cantidadItem = temporalShopingCart[shopping].amount
                  turn = 1
               }
            }
         }
      }

      if (turn == 0) {
         //We add the new product to the shopping cart 
         temporalShopingCart.push(product)
         localStorage.setItem('shoppingCart', JSON.stringify(temporalShopingCart))

      } else {
         localStorage.setItem('shoppingCart', JSON.stringify(temporalShopingCart))
      }

      //reset amount in infoProduct
      setAmount(1);
      setModalShow(false)
      navigate(`/cart/${business_code}/${branchoffice_code}/${machine_code_table}`)
   }

   // Función para incrementar el contador
   const increment = () => {
      setAmount(amount + 1);
      setHandlerEvent(1)
   };

   // Función para decrementar el contador
   const decrement = () => {
      if (amount <= 1) {
         setAmount(1);
      } else {
         setAmount(amount - 1);
      }
      setHandlerEvent(1)
   };


   useEffect(() => {
      setHandlerEvent(0)
   }, [handlerevent])

   useEffect(() => {
      sethandlerEventAction(0)
   }, [handlerEventAction])
   return (
      <Modal
         show={modalShow}
         onHide={() => setModalShow(false)}
         size="lg"
         centered>
         <Modal.Body className='p-0'>
            <section className={segmentsGroup.length > 0 ? 'ecomerce-container-product-0' : ""}>
               <div className='ecomerce-container-1'>
                  <div className='position-relative'>

                     <div className='position-absolute top-0 start-0 p-2 w-100 d-flex justify-content-between '>
                        <button className='btn btn-header-ecommerce-product  btn-sm rounded-3' onClick={() => setModalShow(false)}>
                           <i className="uil uil-angle-left-b icon-header-ecommerce"></i>
                        </button>
                        <button className='btn btn-header-ecommerce-product   btn-sm rounded-3' onClick={() => setModalViewImg(true)}>
                           <i class="uil uil-search-plus icon-header-ecommerce"></i>
                        </button>
                     </div>
                     <img className='img-ecommerce-menu-product rounded-3 '
                        src={selectedGroup.img} alt="..." />
                  </div>
                  <div className='ecomerce-container-info mx-2'>
                     <h4 className='ecomerce-container-info-title fw-bold text-center'>{selectedGroup.name} </h4>
                     <p className='ecomerce-container-info-description text-secondary opacity-75'>{selectedGroup.description}</p>

                  </div>

                  {selectedGroup.setGroups
                     ? <>{Tabs()}</>
                     : <></>}

                  {segmentsGroup.length > 0 ? <>
                     <div className='ecomerce-container-section-0 border border-1 border-light pb-5'>
                        {/* Content Tabs  */}
                        {segmentsGroup.map((segment) => (
                           <div className='m-1'>

                              <h6 className='fw-bold mt-2'>{segment.name}</h6>
                              <div className="container-list-categories d-flex overflow-auto py-1 gap-1 overflow-x ps-1">
                                 {segment.items.map((item, index) => (
                                    <>

                                       {/* <div className='list-pre-orders-inputs '>
                                       <input
                                          className="list-group-item-check "
                                          type={segment.type == 'MULT' ? "checkbox" : "radio"}
                                          id={"listGroupRadioGrid" + segment.segment_id + item.item_id}
                                          name={"listGroupRadioGrid" + segment.segment_id}
                                          defaultChecked={segment.segment_item_id_default == null ? index == 0 ? true : false : segment.segment_item_id_default == item.item_id ? true : false}
                                       />
                                       <label className="list-group-item rounded-3 mb-0 fw-bold text-truncate bg-white rounded-2 p-0 categories-menu shadow-sm"
                                          htmlFor={"listGroupRadioGrid" + segment.segment_id + item.item_id}
                                       >
                                          <div className="d-flex flex-block   gap-1 py-1 rounded" >
                                             <img src={item.img}
                                                alt="Product" className="rounded  flex-shrink-0  img-categories-menu" />
                                          </div>
                                          <div className="d-block px-1">
                                             <span className={`text-categories-menu text-center ${item.name.length > 10 ? 'text-categories-menu-slider ' : 'text-categories-menu '}`}>
                                                {item.name}
                                             </span>
                                             <span className='d-block text-center'> $ {parseFloat(item.price).toFixed(2)}</span>
                                          </div>
                                       </label>
                                    </div> */}

                                       <label class="card-segments" htmlFor={"listGroupRadioGrid" + segment.segment_id + item.item_id} >
                                          <input class="radio-segments"
                                             type={segment.type == 'MULT' ? "checkbox" : "radio"}
                                             id={"listGroupRadioGrid" + segment.segment_id + item.item_id}
                                             name={"listGroupRadioGrid" + segment.segment_id}
                                             //defaultChecked={segment.segment_item_id_default == null ? index == 0 ? true : false : segment.segment_item_id_default == item.item_id ? true : false}
                                             defaultChecked={segment.default_items.length == 0 ? index == 0 ? true : false : segment.default_items.find((default_item) => default_item.item_id == item.item_id) != undefined ? true : false}


                                          />

                                          <span class="plan-details-segments h-100">
                                             <div className="py-1 rounded d-flex justify-content-center" >
                                                <img src={item.img}
                                                   alt="Product" className="rounded-3 border img-details-segments" />
                                             </div>
                                             <span class="plan-type-segments text-wrap mb-auto"> {item.name}</span>
                                             <span className='plan-cost-segments text-center'>$ {parseFloat(item.price).toFixed(2)}</span>
                                          </span>
                                       </label>



                                    </>
                                 ))}

                              </div>
                           </div>
                        ))}
                     </div>
                  </> : <></>}


                  <div className='p-1'>
                     <div className='d-flex justify-content-between align-items-center'>
                        <span className='modal-value-product m-2'>
                           $ {parseFloat(selectedGroup.price).toFixed(2)}
                        </span>
                        <div className='d-flex justify-content-around gap-1 align-items-center m-2'>
                           <div onClick={() => decrement()}><span><i class="uil uil-minus-circle item-count-product"></i></span></div>
                           <span className=' item-count-product'>{amount}</span>
                           <div onClick={() => increment()}><span><i class="uil uil-plus-circle item-count-product"></i></span></div>
                        </div>
                     </div>
                     {selectedGroup.combo_id ? <><button className='btn btn-add-product w-100'  > Continuar</button></> : <>
                        <button className='btn btn-add-product w-100' onClick={() => formatJsonToAddShoppingCart()} > Agregar producto</button></>}
                  </div>

               </div>
            </section>




            <Modal
               show={modalViewImg}
               onHide={() => setModalViewImg(false)}
               size="sm"
               centered>
               <Modal.Body>
                  <img src={selectedGroup.img} className='img-ecommerce-menu-product-preview rounded-4'></img>
                  <div className="d-flex justify-content-center mt-2">
                     <button className='btn btn-outline-danger' onClick={handleClose}>Cerrar </button>
                  </div>
               </Modal.Body>
            </Modal>

            <ModalIngredient setShowModalIngredient={setShowModalIngredient} showModalIngredient={showModalIngredient} groupNow={groupNow} logo={logo} background={''} formatJsonToAddShoppingCart={formatJsonToAddShoppingCart} segmentsNow={segmentsNow}  ></ModalIngredient>


         </Modal.Body>

      </Modal>


   )
}

