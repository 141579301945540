import React, { useState, useEffect } from 'react';
import { useNavigate, useParams, useLocation, NavLink } from 'react-router-dom';
import { Header } from '../common/Header/Header'
import BtnAdd from '../common/BarMenu/btnAdd';
import initData from '../Home/initData';
import ErrorAlert from '../Alerts/ErrorAlert';
import LoadingAlert from '../Alerts/LoadingAlert';
import BarMenu from '../common/BarMenu/BarMenu';
import { PreOrderFetchAPI } from '../../api/PaymentPost/PreOrder';
import { TablesFetchAPI } from '../../api/Tables';
import ModalNfc from './ModalNfc';
import Modal from 'react-bootstrap/Modal';
//CSS
import './cart.css';
import Payments from './Payments';

export const ShoppingCart = () => {
   const navigate = useNavigate();
   const { business_code, branchoffice_code, machine_code_table } = useParams();
   const [handlerEvent, setHandlerEvent] = useState(0);
   const [modalShow, setModalShow] = React.useState(false);
   const [shoppingcart, setShopingcart] = useState([]);
   const [shoppingCartCombos, setShoppingCartCombos] = useState([])
   const [payments, setPayments] = useState([]);
   const [selectedPayment, setSelectedPayment] = useState({})
   const [showModalConfirmation, setShowModalConfirmation] = useState(false)
   const [dataDicountApply, setdataDiscountApply] = useState([])


   //Totals states 
   const [invoiceTip, setInvoiceTip] = useState(0)
   const [totaltopay, settotaltopay] = useState(0)
   const [invoiceTotal, setInvoiceTotal] = useState(0);
   const [invoiceTotalItbms, setInvoiceTotalItbms] = useState(0);
   const [invoiceSubtotal, setInvoiceSubtotal] = useState(0);
   const [invoiceTotalDiscount, setInvoiceTotalDiscount] = useState(0);
   const [taxesArrayAll, setTaxesArrayAll] = useState([]);


   let table = localStorage.getItem('table');

   if (table !== null && table !== undefined && table !== '') {
      table = JSON.parse(table);
   }
   //=====================CLEAR SHOPPINGCART=============================

   const clearShoppingcart = () => {
      localStorage.setItem('shoppingCart', JSON.stringify([]))
      localStorage.setItem('shoppingCartCombos', JSON.stringify([]))
      setShoppingCartCombos([])
      setShopingcart([])
      calculateInvoiceTotals([], [])
      navigate(`/home/${business_code}/${branchoffice_code}/${machine_code_table}`);
   }

   //==========================CALCULATE===========================

   //decimal reduction
   const decimalReduction = (value) => {

      let string = value.toString()
      let arrayString = string.split(".");
      let newDecimalString = []
      let sample = ''
      if (arrayString[1] != undefined) {
         newDecimalString = arrayString[1].slice(0, 2);
         sample = arrayString[0] + "." + newDecimalString
         console.log("Hice la reduccion");
      } else {
         console.log("Array: " + arrayString);
         console.log("Decimal: " + newDecimalString);
         console.log("Nuevo valor: " + sample);
         sample = string;
      }

      let endValue = parseFloat(sample)
      return endValue
   }

   // Función para redondear hacia arriba si el tercer decimal es mayor a 0
   function roundUpToTwoDecimals(value) {
      console.log("Nuevo redondeo de tax");
      console.log(value);
      // Verificamos si hay más de dos decimales
      const decimals = value.toString().split('.')[1];

      if (decimals && decimals.length > 2) {
         // Si el tercer decimal es mayor que 0, redondeamos hacia arriba
         if (parseInt(decimals[2]) > 0) {
            return Math.ceil(value * 100) / 100;
         }
      }

      console.log(parseFloat(value.toFixed(2)));

      // Si ya tiene dos decimales o menos, devolvemos el valor tal cual
      return parseFloat(value.toFixed(2));
   }

   //Function to calculate the invoice totals
   const calculateInvoiceTotals = (products, combos) => {

      //For every new calculate we set the totals
      let taxesArray = [];
      let invoiceTotalDiscount = 0
      let invoiceSubtotal = 0
      let invoiceTotalItbms = 0
      let invoiceTotal = 0

      //if we dont we products we put the totals in 0 for default
      if ((products.length + combos.length) === 0) {
         //We calculate the totals of the invoice
         setInvoiceTotalDiscount(0)
         setInvoiceSubtotal(0);
         setInvoiceTotalItbms(0)
         setInvoiceTotal(0);
         setTaxesArrayAll(taxesArray)
      } else {

         //For every combos we calculate
         combos.forEach((combo) => {

            byProductandCombo(combo)
         })

         products.forEach((group) => {
            byProductandCombo(group)
         })

         //Esta funcion calcula los valores por producto o combo por posicion
         function byProductandCombo(item) {

            //Variables
            let total_product_price = 0;
            let total_product_itbms = 0;
            let product_total_discount = 0;
            //We set the total discount of the product
            product_total_discount = (item.unit_discount * item.amount);
            //We set the total of the product
            total_product_price = (item.amount * (item.price - item.unit_discount));

            //Calculate tax by group--------------------------------
            let taxesGroup = item.taxes;
            taxesGroup.forEach(tax => {
               //calculate tax  value and add it to array

               let valueTax = roundUpToTwoDecimals((item.amount * ((item.price - item.unit_discount) * tax.taxtValue)));
               console.log(valueTax);
               tax.total = valueTax;
               //Identifier tax of the group position
               var index = taxesArray.findIndex(function (info, i) {
                  return info.tax_id === tax.tax_id
               });

               if (index < 0) {
                  let taxInfoNow = {
                     "dTasaITBMSValue": tax.dTasaITBMSValue,
                     "percentage": tax.percentage,
                     "tax": tax.tax,
                     "tax_id": tax.tax_id,
                     "taxtValue": tax.taxtValue,
                     "total": valueTax
                  }
                  taxesArray.push(taxInfoNow);
               } else {
                  taxesArray[index].total = (taxesArray[index].total + valueTax)
               }
               total_product_itbms = (total_product_itbms + valueTax)

            });
            //-------------------------------------------------------
            //We set the itbms of the product
            //total_product_itbms = (product_info.amount * ((product_info.price - product_info.unit_discount) * product_info.taxtValue));

            //We calculate the totals of the invoice
            invoiceTotalDiscount = invoiceTotalDiscount + product_total_discount;
            invoiceSubtotal = decimalReduction(invoiceSubtotal + total_product_price);
            invoiceTotalItbms = invoiceTotalItbms + total_product_itbms;
            invoiceTotal = invoiceTotal + total_product_price + total_product_itbms

            console.log("invoiceTotalDiscount:" + invoiceTotalDiscount);
            console.log("invoiceSubtotal:" + invoiceSubtotal);
            console.log("invoiceTotalItbms:" + invoiceTotalItbms);
            console.log("invoiceTotal:" + invoiceTotal);
         }


         //We set the totals
         setInvoiceTotalDiscount(invoiceTotalDiscount)
         setInvoiceSubtotal(invoiceSubtotal);
         setInvoiceTotalItbms(invoiceTotalItbms);
         setInvoiceTotal(invoiceTotal);
         setTaxesArrayAll(taxesArray);
         settotaltopay((invoiceTotal + invoiceTip))

         let newArray = []
         //valid discount report
         dataDicountApply.forEach((discount, index) => {
            let discountvalid = 0
            discount.groups.forEach((group) => {
               let productfind = products.find(({ group_id }) => group_id === group.group_id)
               if (productfind) {
                  discountvalid = discountvalid + productfind.discount
               }
            })

            discount.combos.forEach((combo) => {
               let combofind = combos.find(({ combo_id }) => combo_id === combo.combo_id)
               if (combofind) {
                  discountvalid = discountvalid + combofind.discount
               }
            })


            discount.index = index
            if (discountvalid == 0) {
               let indexRemove = index
               dataDicountApply.forEach((item, index) => {
                  if (item.index != indexRemove) {
                     newArray.push(item)
                  }
               })

            } else {
               if (discountvalid != discount.total) {
                  discount.total = discountvalid
               }
            }
         })


         if (newArray.length > 0) {
            setdataDiscountApply(newArray)
         } else {
            setdataDiscountApply(dataDicountApply)
         }
      }

      setHandlerEvent(1)
   }

   //==========================EDIT AMOUNTS PRODUCTS ============================

   //Function to incremet the product amount on the shopping cart
   const decrementProductAmount = (product_index) => {
      //We search the product on the list
      let product = shoppingcart[product_index];
      //We affect the product data
      product.amount = product.amount - 1;
      product.cantidadItem = product.amount
      product.descuento = product.descuento - product.unit_discount;
      product.discount = product.discount - product.unit_discount;

      //We check if the product amount is lower than 0
      if (product.amount <= 0) {
         //if is lower or equal to 0 we delete the product of the shopping cart list
         shoppingcart.splice(product_index, 1);
         setShopingcart([...shoppingcart])
      }

      localStorage.setItem('shoppingCart', JSON.stringify(shoppingcart))
      //recharge shoppingcart
      calculateInvoiceTotals(shoppingcart, shoppingCartCombos)
   };

   //Function to incremet the product amount on the shopping cart
   const incrementProductAmount = (product_index) => {
      //We search the product on the list
      let product = shoppingcart[product_index];
      //We affect the product data
      product.amount = product.amount + 1;
      product.cantidadItem = product.amount;
      product.descuento = product.descuento + product.unit_discount;
      product.discount = product.discount + product.unit_discount;

      localStorage.setItem('shoppingCart', JSON.stringify(shoppingcart))
      //recharge shoppingcart
      calculateInvoiceTotals(shoppingcart, shoppingCartCombos)
   };


   //----------------------Data general-----------------------\

   //consolidates data into corresponding statements
   const dataconsolidation = (generalData) => {
      if (generalData == 'error') { navigate(`/branch/${business_code}`) }
      let data = generalData
      let dataCombos = []
      let dataGroups = []
      if (localStorage.getItem('shoppingCart') == null || localStorage.getItem('shoppingCart') == undefined || localStorage.getItem('shoppingCart') == "") {
         setShopingcart([])
      } else {
         let datashopingcart = JSON.parse(localStorage.getItem('shoppingCart'))
         dataGroups = datashopingcart
         setShopingcart(datashopingcart)

      }

      if (localStorage.getItem('shoppingCartCombos') == null || localStorage.getItem('shoppingCartCombos') == undefined || localStorage.getItem('shoppingCartCombos') == "") {
         setShoppingCartCombos([])
      } else {
         let datashopingcart = JSON.parse(localStorage.getItem('shoppingCartCombos'))
         dataCombos = datashopingcart
         setShoppingCartCombos(datashopingcart)
      }


      if (dataGroups.length > 0 || dataCombos.length > 0) {
         calculateInvoiceTotals(dataGroups, dataCombos)
      }




      //==========Payments==============
      setPayments(data.paymentMethods);

      console.log(data.paymentMethods);
   }

   const handlerInit = async () => {
      try {
         let response = await initData()
         dataconsolidation(response)
      } catch (err) {
         navigate(`/branch/${business_code}`)
         console.log(err);
      }
   }

   const getTableById = async (type) => {
      let token = localStorage.getItem('x-access-machine-token');
      let business = JSON.parse(localStorage.getItem('business'))
      let dataTable = JSON.parse(localStorage.getItem('table'))
      try {

         const response = await TablesFetchAPI.getTableById(dataTable.id, token);
         let tableNow = response.data.table
       
         let codeRoom = localStorage.getItem('codeRoom')
         if (codeRoom != undefined || codeRoom != '' || codeRoom != null) {
            if (codeRoom != tableNow.password) {
               navigate(`/branch/${business_code}/${dataTable.table_code}`)
            }else{
               if(type != 'init'){createPreOrder()}
            }
         } else {
            navigate(`/branch/${business_code}/${dataTable.table_code}`)
         }
         //----------------------------------------------
      } catch (err) {
         navigate(`/branch/${business_code}/${dataTable.table_code}`)
         console.log(err);
      }

   }

   //create preOrder
   const createPreOrder = async () => {

      if ((shoppingcart.length + shoppingCartCombos.length) > 0) {
         LoadingAlert()
         let token = localStorage.getItem('x-access-machine-token');
         let branchoffice = JSON.parse(localStorage.getItem('branchoffice'))
         let machine = JSON.parse(localStorage.getItem('machine'))
         //let table = JSON.parse(localStorage.getItem('table'))

         console.log(shoppingcart);
         let json = {
            "client_name": table.client_name,
            "localizador": "00",
            "client_phone": "0",
            "ruc": "0000",
            "dv": "00",
            "id_Client": 0,
            "dgi_qr": "0",
            "Orden": shoppingcart,
            "combos": shoppingCartCombos,
            "Descuento": invoiceTotalDiscount,
            "Total": invoiceTotal,
            "itbms": invoiceTotalItbms,
            "taxes": taxesArrayAll,
            "subtotal": invoiceSubtotal,
            "machine_id": machine.id,
            "branchoffice_id": branchoffice.id,
            "tipoOrden": "para llevar",
            "paymethod": 0,
            "is_discount_notification": false,
            "is_cupon": false,
            "is_devolution_notification": false,
            "is_pre_order": true, "user": {
               id: 0,
               name: "",
               code: 0
            },
            "table_name": table.name,
            "table_code": table.table_code,
            "table_id": table.id,
            "table_password": table.password
         }

         try {
            const response = await PreOrderFetchAPI.createPreOrder(json, token)

            console.log(response);
            //printOrder(response.data.newTempOrder._id, response.data.newTempOrder.code)
            //setShow(false)
            let message = "Precuenta creada con exito."
            let title = "Operacion exitosa."
            let icon = "success"
            ErrorAlert(message, title, icon)
            setShowModalConfirmation(false)
            setShopingcart([])
            localStorage.setItem('shoppingCart', '[]')
            navigate(`/orders/${business_code}/${branchoffice_code}/${machine_code_table}`)

         } catch (err) {
            console.log(err);
         }
      } else {
         ErrorAlert("Debes agregar  algo al carrito", "No hay productos en el carrito", "info")
      }
   }

   const cardSegments = (segment) => {
      return (<><div class="list-group w-100">
         <a href="#" class="list-group-item list-group-item-action d-flex gap-3 py-3  border-0" aria-current="true">
            <div class="d-flex gap-2 w-100 justify-content-between">
               <div>
                  <h6 class=" opacity-50 mb-0">{segment.segment_name}</h6>
                  <h6 class="mb-0">{segment.item.name}</h6>
                  {/* <p class="mb-0 opacity-75">Some placeholder content in a paragraph.</p> */}
               </div>
               <small class="opacity-75 text-nowrap fw-bold">+ ${segment.price}</small>
            </div>
         </a>

      </div></>)
   }

   //layout card product
   const cardProduct = (product, index, setGroupCombo) => {
      return (<>
         <div class="ecommerce-menu-card d-flex align-items-center bg-white  border  border-1  mt-1 mx-1 p-1 rounded-4">
            <div class="flex-shrink-0">
               {setGroupCombo == false?<><img src={product.img}
                  className='img-ecommerce-menu-card rounded-3 border' alt='...' /></>:<></>}
               
            </div>
            <div class="flex-grow-1 ms-3">
               <div className='mt-2 me-2'>
                  <span className='mt-1 title-ecommerce-menu-card '> {product.name} </span>
                  {/* <span className='mt-1 d-block description-ecommerce-menu-card '>
                            Lorem ipsum dolor sit amet consectetur adipisicing elit. Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                             Architecto quaerat culpa fuga alias odio, nesciunt voluptate ipsum nulla.
                              Accusamus sit a eum laborum nobis earum expedita blanditiis minus nihil voluptate.
                          
                         </span> */}
                  <div className='d-block'>
                     <details>
                        <summary>

                           Detalles
                        </summary>
                        <div className='mt-1'>

                           <div class="d-flex flex-column flex-md-row gap-1 align-items-center justify-content-center">
                              {/* Item 1 */}
                              {product.segments ? <>
                                 {product.segments.map((segment) => (
                                    cardSegments(segment)))}</> : <>

                                 {product.setGroups.map((setGroup) => (<>
                                    <div class="list-group w-100">{setGroup.name}</div>
                                    {setGroup.groups.map((group, index) =>(
                                       cardProduct(group, index, true)
                                    ))}

                                 </>))}
                              </>}
                           </div>
                        </div>
                     </details>
                  </div>
                  
                  <div className='d-flex justify-content-between align-items-center'>
                     <span className='mt-1 d-block value-ecommerce-menu-card '> $ {product.price} </span>

                     {setGroupCombo ==  false?<><div className='d-flex justify-content-around gap-1 align-items-center'>
                        <div onClick={() => decrementProductAmount(index)}><span><i class="uil uil-minus-circle item-count-product"></i></span></div>
                        <span className='value-ecommerce-menu-card text-dark'>{product.amount}</span>
                        <div onClick={() => incrementProductAmount(index)}><span><i class="uil uil-plus-circle item-count-product"></i></span></div>
                     </div></>:<></>}
                     
                  </div>

               </div>
            </div>
         </div>
      </>)
   }

  

   useEffect(() => {
      if(localStorage.getItem('codeRoom')  != undefined && localStorage.getItem('codeRoom')  != '' && localStorage.getItem('codeRoom')  != null){
         getTableById('init') 
      }
      handlerInit()
   }, [])

   useEffect(() => {
      setHandlerEvent(0)
   }, [handlerEvent])
   return (

      <>
         <section className='ecomerce-container-0'>
            <div className='ecomerce-container-1 position-realtive mx-2 pb-2'>
               <Header></Header>



               <h4 className='text-white fw-bold text-center '>Mi Orden</h4>
               <div className='ecomerce-container-section-0 tracking-in-expand-forward-top border rounded-4 bg-white pt-0'>



                  {(shoppingcart.length + shoppingCartCombos.length) === 0
                     ? <>

                        <span className='mt-0 px-4 mt-5'>

                           <div class="position-relative p-4 text-center text-muted bg-body rounded-4 mx-3">

                              <span className='d-block  icon-sad-lg opacity-25'>
                                 <i class="uil uil-sad-squint"></i>
                              </span>
                              ¡El carrito esta vacío, no
                              contiene productos en este momento!
                           </div>
                        </span>

                     </>
                     : <>

                        {shoppingcart.map((product, index) => (
                           cardProduct(product, index, false)
                        ))}

                        {shoppingCartCombos.map((product, index) => (
                           cardProduct(product, index, false)
                        ))}

                     </>}






               </div>

               <div className="d-flex justify-content-between ">
                  <div className="d-flex m-1 text-center">
                     <span className="text-muted border border-1 rounded-3 p-1 text-subtitel-order-ecommerce  bg-light">
                        Descuento: $
                        <span translate='no' className='fw-bold'>{invoiceTotalDiscount}</span>
                     </span>
                  </div>
                  <div className="d-flex m-1 text-center">
                     <span className="text-muted border border-1 rounded-3 p-1 text-subtitel-order-ecommerce bg-light">
                        Impuesto: $
                        <span translate='no' className='fw-bold text-center'>{invoiceTotalItbms}</span>
                     </span>
                  </div>
                  <div className="d-flex m-1 text-center">
                     <span className="text-muted border border-1 rounded-3 p-1 text-subtitel-order-ecommerce bg-light">
                        Subtotal: $
                        <span translate='no' className='fw-bold text-center'>{invoiceSubtotal}</span>
                     </span>
                  </div>
               </div>

               <div className='p-1'>
                  <div className='d-flex justify-content-between align-items-center total-shopping'>
                     <span className='item-value-product'>
                        Total
                     </span>
                     <span className='item-value-product'>
                        $ {invoiceTotal.toFixed(2)}
                     </span>
                  </div>

                  <div className='flex-column'>
                     <div className='w-100'>


                     </div>
                     <div className=' flex-column'>
                        <BtnAdd />
                        {/* <button className='btn btn-primary btn-sm w-100 '>Agregar más productos</button> */}
                        <div className='d-flex justify-content-between align-items-center '>
                           <button className='btn btn-danger w-100 p-2 m-1 fw-bold' onClick={() => clearShoppingcart()}  >
                              Cancelar
                           </button>
                           <button className='btn btn-add-product w-100 p-2 m-1' onClick={() => setShowModalConfirmation(true)}  >
                              Ordernar
                              {/* $ {invoiceTotal} */}
                           </button>
                        </div>
                     </div>

                  </div>


               </div>

               <div className='fake-div'>

               </div>

               {/* <BarMenu shoppingcart={shoppingcart} ></BarMenu> */}
            </div>
         </section>

         <Payments modalShow={modalShow}
            setModalShow={setModalShow}
            payments={payments}
            invoiceTotal={invoiceTotal}
            selectedPayment={selectedPayment} setSelectedPayment={setSelectedPayment} />



         <Modal
            show={showModalConfirmation}
            size='lg'
            onHide={() => setShowModalConfirmation(false)}
            centered
         >

            <div className='mt-2 text-center p-1'>

               <h4 className='fw-bold'>¿Desea ordenar a la habitacion?</h4>

               {table != undefined  && table != '' && table != null?<><div className='mt-5'>
                  <h1 className='text-center txtRoom mt-3'>{table.name}</h1>
               </div></>:<></>}
               
            </div>
            <Modal.Body>
               <div className='d-flex justify-content-between justify-content-lg-end mt-2'>
                  <button className='btn bg-danger-subtle text-danger  border-0 fw-bold '
                     onClick={() => setShowModalConfirmation(false)}> Cancelar </button>



                  <button className='btn bg-success-subtle text-success fw-bold border-0  ms-1'
                     onClick={async () => (getTableById())}> Ordenar </button>
               </div>
            </Modal.Body>
         </Modal>


      </>

   )
}

export default ShoppingCart