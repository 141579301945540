import React from 'react'
import Modal from 'react-bootstrap/Modal';
const InfoOrdersModal = ({ modalShow, setModalShow, selectedOrder }) => {

   const cardProduct = (product, index) => {
      return (<>
         <div class="ecommerce-menu-card d-flex align-items-center bg-white rounded-3 mt-1 shadow-sm mx-1 p-1">
            <div class="flex-shrink-0">
               <img src={product.img}
                  className='img-ecommerce-menu-card rounded-3 border' alt='...' />
            </div>
            <div class="flex-grow-1 ms-3">
               <div className='mt-2 me-2'>


                  <div className='d-flex justify-content-between'>
                     <span className='mt-1 title-ecommerce-menu-card '> {product.name} </span>
                     <div className='d-flex justify-content-between text-nowrap'>
                        <span className='mt-1 d-block value-ecommerce-menu-card'> $ {product.price} </span>
                     </div>
                  </div>


                  {/* <span className='mt-1 d-block description-ecommerce-menu-card '>
                            Lorem ipsum dolor sit amet consectetur adipisicing elit. Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                             Architecto quaerat culpa fuga alias odio, nesciunt voluptate ipsum nulla.
                              Accusamus sit a eum laborum nobis earum expedita blanditiis minus nihil voluptate.
                          
                         </span> */}
                  <div className='d-block'>
                     <details>
                        <summary className='text-muted opacity-75'>
                           Detalles
                        </summary>
                        <div className='mt-1'>

                           <div class="d-flex flex-column flex-md-row gap-1 align-items-center justify-content-center">
                              {/* Item 1 */}

                              {product.segments ? <>
                                 {product.segments.map((segment) => (
                                    <div class="list-group  w-100">
                                       <a href="#" class="list-group-item list-group-item-action d-flex gap-3 py-1 border-0" aria-current="true">
                                          <div class="d-flex gap-2 w-100 justify-content-between opacity-50">
                                             <div className=' '>
                                                <h6 class="mb-0 fw-normal">{segment.item.name}</h6>
                                                {/* <p class="mb-0 opacity-75">Some placeholder content in a paragraph.</p> */}
                                             </div>
                                             <small class=" text-nowrap">+ ${segment.item.price}</small>
                                          </div>
                                       </a>

                                    </div>))}</> : <>

                                 {product.setGroups.map((setGroup) => (<>
                                    <div>{setGroup.name}</div>
                                    {setGroup.groups.map((group, index) => (
                                       cardProduct(group, index)))}
                                 </>))}
                              </>}
                           </div>

                        </div>
                     </details>

                  </div>

               </div>
            </div>
         </div>
      </>)
   }
   return (
      <>

         <Modal
            show={modalShow}
            onHide={() => setModalShow(false)}
            size="lg"

            centered
         >

            <Modal.Body className='p-0'>

               <section className='ecomerce-container-product-0'>
                  <div className='ecomerce-container-1'>
                     <div className='position-relative'>

                        <div className=' p-2 w-100 d-flex justify-content-between align-items-center  '>
                           <button className='btn btn-header-ecommerce-product  btn-sm rounded-3' onClick={() => setModalShow(false)}>
                              <i className="uil uil-angle-left-b icon-header-ecommerce"></i>
                           </button>
                           <h4 className='fw-bold text-center'>
                              <i class="uil uil-bill me-1"></i>
                              Orden # {selectedOrder.transaction_code}
                           </h4>
                           <span className='px-3'></span>
                        </div>

                     </div>


                     {/* <div className='ecomerce-container-info mx-2'>
                        <h4 className='ecomerce-container-info-title fw-bold mt-2'>Productos</h4>

                     </div> */}

                     <div className='ecomerce-container-section-0 border  pb-5 zig-zag-border extra-last-padding'>

                        {selectedOrder.groups.map((product, index) => (
                           cardProduct(product, index)
                        ))}

                        {selectedOrder.combos.map((combo, indec) => (
                           cardProduct(combo, indec)
                        ))}

                     </div>

                     <div className='px-3 border-1 border rounded m-2 border-seconday border-light'>
                        <div className="d-flex justify-content-between gap-3 mb-1  mt-1 pt-1 btn-sm">
                           <h2 className=" text-success fs-5"><i class="uil uil-usd-square"></i> <b>Total: </b></h2>
                           <h2 className=" text-success fs-5"> <b>$  {selectedOrder.total.toFixed(2)}  </b></h2>
                        </div>

                        {selectedOrder.payment ? <>
                           <div className="d-flex justify-content-between gap-3 mb-1  mt-1 pt-1 btn-sm">
                              <h2 className=" text-primary fs-5"><i class="uil uil-usd-square"></i> <b>Método de pago: </b></h2>
                              <h2 className=" text-primary fs-5"> <b> Visa  </b></h2>
                           </div>

                        </>
                           : <></>

                        }



                     </div>
                  </div>
               </section>
            </Modal.Body>


         </Modal>

      </>
   )
}

export default InfoOrdersModal